.item {
  width: 100%;
  display: flex;
  text-decoration: none;
  margin-bottom: 5px;
  padding: 5px 14px;
  transition: all .15s ease;
  border-left: 1.5px solid transparent;
  background: #b13510;
}

.item:hover {
  background: #972e0e;
}

a {
  text-decoration: none;
}

.title {
  color: #d2d2d2;
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.icon {
  display: inline;
  color: rgb(255, 255, 255);
  font-size: 16px;
}

.close {
  position: absolute;
  font-size: 10px;
  right: 0;
  margin-right: 3px;
  margin-top: -5px;
  cursor: pointer;
}

a .close :hover {
  color: #1a1a1a;
  transition: all .15s ease;
}