.item {
  width: 100%;
  display: flex;
  text-decoration: none;
  margin-bottom: 5px;
  padding: 10px 26px;
  transition: border .15s ease;
  border-left: 1.5px solid transparent;

}

.item:hover {
  color: #436ada !important;
  border-left: 1.5px solid #39adff;
  background: linear-gradient(90deg, rgb(49 121 211 / 59%) 0.43%, rgba(25, 25, 25, 0));
}

a[data-disabled="true"] {
  cursor: not-allowed;
  
  & > .item:hover {
    background: linear-gradient(90deg, rgba(67, 83, 104, 0.59) 0.43%, rgba(25, 25, 25, 0));
  }
}


.icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  transition: all .5s ease;
  color: white;
}

div {
  text-decoration: none;
}

.title {
  color: #d2d2d2;
  margin-left: 17px;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.active_link > div {
  background: linear-gradient(90deg, rgb(49 121 211 / 59%) 0.43%, rgba(25, 25, 25, 0)) !important;
}