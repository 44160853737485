.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  flex-shrink: 0;
  width: 232px;
  height: calc(100vh - 60px);
  margin: 30px auto;
  background-color: #191919;
  box-shadow: none;
  border-radius: 18px;
  z-index: 9;
  overflow: hidden;
}

.inner {
  overflow-y: auto;
  position: sticky;
  height: 100vh;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.inner::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.sidebar::after {
  pointer-events: none;
  content: '';
  display: flex;
  position: absolute;
  height:30px;
  bottom: 0;
  width:100%;
  background: linear-gradient(0deg, rgb(49 121 211 / 10%), rgba(25, 25, 25, 0));
}

