.button_notice {
  position: relative;
  padding: 3px;
  margin-right: 20px;
  font-size: 25px;
}

div {
  text-decoration: none;
  color: #d2d2d2;
}

.button_notice:hover {
  cursor: pointer;
}

.active::after {
  content: attr(data-count);
  position: absolute;
  background: linear-gradient(180deg, rgba(177,53,16,1) 50%, rgba(255,78,25,1) 100%);
  border-radius: 50%;
  font-size: 11px;
  width: 13px;
  height: 13px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
  padding: 3px;
  top: 0;
  margin-left: -10px;
}