.todayorders { 
  width: 212px;
  min-width: 212px;
  margin-bottom: 30px;
  padding: 15px 0px 15px 18px;
  background-color: #1a1a1a;
  border-radius: 16px;
}

.stats {
  margin-top: 18px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}

.subtitle {
  font-size: 10px;
  line-height: 12px;
  color: #C7C7C7;
}

.counter {
}

.count {
  display: inline-block;
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
}

.measure {
  display: inline-block;
  margin-left: 10px;;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #C7C7C7;
}

.beforeWrap {
  margin-top: -7px;
}

.before {
  margin-top: -15px;
  border-radius: 9px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  height: 18px;
  line-height: 19px;
  padding: 2px 6px;
  color: #FFFFFF;
}

.green {
  background: #2fa61b;
}

.red {
  background: #B13510;
}

.link {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.link:hover {
  color:#edf5ff;
  text-shadow: 0px 0px 14px #264c7c
}