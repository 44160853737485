.statsfield {
  display: flex;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
  flex-direction: row;
  gap: 30px;
  z-index: 1;
}

.longfield {
  width: 342px;
  padding: 2px 13px;
  background-color: #1a1a1a;
  min-height: 78px;
  border-radius: 16px;

  & > .title {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;

    & > .right {
      font-weight: 400;
      text-decoration: dashed;
      color: #818491;

      &:hover {
        color: #fff;
      }
    }
  }
}

.field {
  position: relative;
  // width: 156px;
  width: 212px;
  padding: 2px 13px;
  background-color: #1a1a1a;
  // min-height: 78px;
  min-height: 100px;
  border-radius: 16px;

  & > .title {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;

    & > .right {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;

      & > a {
        color: #818491;
        border-bottom: 1px dotted #818491;
        margin: 0px 5px;

        &:last-of-type {
          margin: 0px 0px 0px 5px;
        }

        &.selected {
          pointer-events: none;
          border-bottom: none;
          color: #fff;
          font-weight: 600;
        }

        &:hover {
          color: #fff;
          border-bottom: 1px dotted #fff;
        }
      }

    }
  }

  & > .data ~ .data {
    padding-top: 6px;
  }

  & > .data {
    display: flex;
    padding-top: 8px;
    flex-direction: row;
    justify-content: space-between;

    & > .name {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
    }

    & > .badge {
      position: relative;
      margin-left: 6px;
      padding: 2px 7px 2px 6px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 9px;
      line-height: 10px;
      display: flex;
      background-color: #262626;
      color: #818491;
      z-index: 1;
    }

    & > .active {
      background-color: #085E1B;
      color: #6EF28B;
      z-index: 1;

      &::after {
        position: absolute;
        content: '';
        width: 80%;
        height: 75%;
        right: 0;
        left: 0;
        margin: 0 auto;
        background-color: #085E1B;
        filter: blur(10px);
        z-index: -1;
        opacity: .5;
      }
    }

    & > .inactive {
      background-color: #590D0D;
      color: #EB7575;
      z-index: 1;

      &::after {
        position: absolute;
        content: '';
        width: 80%;
        height: 75%;
        right: 0;
        left: 0;
        margin: 0 auto;
        background-color: #590D0D;
        filter: blur(10px);
        z-index: -1;
        opacity: .5;
      }
    }
  }

  & > .numbers ~ .numbers {
    padding-top: 8px;
  }

  & > .numbers {
    display: flex;
    flex-direction: row;
    padding-top: 13px;

    & > .arrowUp {
      display: inline-block;
      position: relative;
      width: 11px;
      height: 11px;
      margin-top: 3px;
      z-index: 1;
      line-height: 21px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%2314E442' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 1h5m0 0v5m0-5L1 7'/%3E%3C/svg%3E") no-repeat;
      background-size: cover;
      background-position: center;

      &::after {
        position: absolute;
        width: 11px;
        height: 11px;
        content: '';
        background-color: #14E442;
        border-radius: 50%;
        filter: blur(5px);
        z-index: 0;
        opacity: .5;
      }
    }

    & > .arrowDown {
      display: inline-block;
      position: relative;
      width: 11px;
      height: 11px;
      margin-top: 3px;
      z-index: 1;
      line-height: 21px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%23E12B2B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 2v5m0 0H2m5 0L1 1'/%3E%3C/svg%3E") no-repeat;
      background-size: cover;
      background-position: center;

      &::after {
        position: absolute;
        width: 11px;
        height: 11px;
        content: '';
        background-color: #E12B2B;
        border-radius: 50%;
        filter: blur(5px);
        z-index: 0;
        opacity: .5;
      }
    }

    & > .dot {
      position: relative;
      width: 8px;
      height: 8px;
      background-color: #14E442;
      border-radius: 50%;
      margin: 5px 0;
      z-index: 1;

      &::after {
        position: absolute;
        width: 8px;
        height: 8px;
        content: '';
        background-color: #14E442;
        border-radius: 50%;
        filter: blur(5px);
        z-index: 0;
        opacity: .5;
      }
    }


    & > .number {
      padding-left: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;

      & > span {
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}