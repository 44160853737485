.adsGraphBlock {
  position: relative;
  margin-top: 25px;
}

.header{
  display: block;
}
.headerTitle {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.right {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  position: absolute;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.25s ease;
}

.right:hover {
  text-shadow: 0px 0px 14px #264c7c;
}

.block {
  margin-top: 10px;
  position: relative;
  width: 454px;
  min-width: 454px;
  height: 250px;
  background-color: #1a1a1a;
  border-radius: 16px;
  overflow: hidden;
}

.title {
  margin-left: 18px;
  margin-top: 15px;
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}

.legend {
  position: absolute;
  width: 111px;
  height: 37px;
  background-color: #10100F;
  bottom: 15px;
  right: 18px;
  border-radius: 9px;
  z-index: 10;
  padding: 4px;
}

.row {
  line-height: 12px;
}

.legendTitle {
  display: inline-block;
  font-weight: 375;
  font-size: 10px;
  margin-left: 3px;
}

.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 1px;
  background-color: #60A6FF;
}

.red {
  background-color: #B03511;
}