.circleTop {
    position: absolute;
    width: 530px;
    aspect-ratio: 1;
    border-radius: 100%;
    transform: rotate(0deg);
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -530px;
    box-shadow: 0px 0px 300px -16px #7564e7;
    animation: 4s rotate ease infinite;
  
    @media (max-width: 768px) {
      width: 350px;
      top: -350px;
    }
  }
@keyframes rotate {
    0% {
        box-shadow: 0px 0px 300px -16px #7564e7;
    }
    50% {
        box-shadow: 0px 0px 300px -16px #584584;
    }
    100% {
        box-shadow: 0px 0px 300px -16px #7564e7;
    }
}

  .circleTop2 {
    position: absolute;
    width: 57.94px;
    height: 57.93px;
    left: 1127px;
    top: 126px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
  
    @media (max-width: 768px) {
      display: none;
    }
  }
  .circleTop3 {
    position: absolute;
    width: 122.96px;
    height: 122.94px;
    left: 1319px;
    top: 280px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
    @media (max-width: 768px) {
      display: none;
    }
  }
  .circleTop4 {
    position: absolute;
    width: 28.17px;
    height: 28.17px;
    left: 80px;
    top: 506px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  .rectangleLong1 {
    position: absolute;
    width: 246.83px;
    height: 128px;
    left: 787px;
    top: 489px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  .rectangleLong2 {
    position: absolute;
    width: 269.87px;
    height: 128px;
    left: 1135px;
    top: 733px;
    @media (max-width: 768px) {
      display: none;
    }
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
  }
  
  .rectangleLong3 {
    position: absolute;
    width: 600px;
    height: 128px;
    left: -428px;
    top: 438px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
  }
  
  .rectangleSmall1 {
    position: absolute;
    width: 669.87px;
    height: 28.17px;
    left: -535px;
    top: 306px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 289px;
    transform: rotate(33.69deg);
  }
  
  .rectangleSmall2 {
    position: absolute;
    width: 88.61px;
    height: 189.62px;
    left: 940px;
    top: -120px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 122.5px;
    transform: rotate(33.69deg);
    @media (max-width: 768px) {
      display: none;
    }
  }
  .rectangleSmall3 {
    position: absolute;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: -10px;
    filter: blur(30px);
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 1000px;
    transform: rotate(90deg);
    animation: 2s fluid infinite ease-in-out;
  }
  
  @keyframes fluid {
    0% {
      width: 120px;
      height: 120px;
      background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    }
    50% {
      width: 50px;
      height: 250px;
      margin: -35px auto;
      filter: blur(30px) hue-rotate(70deg);
    }
    100% {
      width: 120px;
      height: 120px;
      background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    }
  }
  
  .rectangleBig {
    position: absolute;
    width: 180px;
    height: 180px;
    left: 0px;
    top: -50px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 39px;
    transform: rotate(148.96deg);
  }
  
  .circleOne {
    position: absolute;
    width: 180px;
    height: 180px;
    left: 415px;
    top: 526px;
  
    background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
    box-shadow: 0px 0px 224px -16px #584584;
    border-radius: 122.5px;
    transform: rotate(33.69deg);
  }
  
  .exclamation {
    position: absolute;
    z-index: -1;
    right: 200px;
    top: 10px;
    @media (max-width: 768px) {
      right: 50px;
      left: inherit;
    }
    & > .line {
      position: absolute;
      width: 30px;
      height: 120px;
  
      background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
      box-shadow: 0px 0px 224px -16px #584584;
      border-radius: 20px;
      transform: rotate(-165deg);
    }
  
    & .dot {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 135px;
      left: -23px;
  
      background: linear-gradient(270deg, #7564e7 0%, #55e3f9 100%);
      box-shadow: 0px 0px 224px -16px #584584;
      border-radius: 20px;
      transform: rotate(-165deg);
    }
  }
  
  .rpmBg {
    position: absolute;
    z-index: -1;
    width: 100%;
    min-width: 100%;
    height: 2200px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(16, 16, 15, 1) 100%
    );
  }
  
  .betaBg {
    position: absolute;
    pointer-events: none;
    z-index: -1;
    margin-top: 0px;
    width: 100%;
    min-width: 100%;
    height: 2200px;
    background: linear-gradient(
      180deg,
      rgba(10, 14, 24, 0) 0%,
      rgba(8, 13, 25, 0.9) 41.2%,
      rgba(16, 16, 15, 0.9) 98.89%
    );
  }
  