.account_top {
  display: flex;
  position: relative;
  flex: inherit;
  align-items: center;
  height: 40px;
  padding: 4px;
  background: #191919;
  border-radius: 90px;
  cursor: pointer;
  height: 42px;
  border-radius: 24px;
}


.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.avatar_img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: radial-gradient(#fff,#000);
  mask-image: radial-gradient(#fff,#000);

  & > img {
    width: 100%;
    height: 100%;
    max-height: auto;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.dropdown {
  position: relative;
  margin: 6px 8px 4px 8px;
  // width: 10px;
  // height: 10px;
  // border-bottom: 2px solid #fff;
  // border-right: 2px solid #fff;
  // transform: rotate(45deg);

  // &::after {
  //   position: absolute;
  //   content: '';
  //   margin: 8px 4px 4px 8px;
  //   width: 4px;
  //   height: 4px;
  //   border-bottom: 1px solid #eee;
  //   border-right: 1px solid #eee;
  // }

  // &::before {
  //   position: absolute;
  //   content: '';
  //   margin: 0px 4px 4px 0px;
  //   width: 4px;
  //   height: 4px;
  //   border-bottom: 1px solid #eee;
  //   border-right: 1px solid #eee;
  // }
}