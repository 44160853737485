.header{
  display: block;
}
.headerTitle {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.right {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  position: absolute;
  right: -10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.25s ease;
}

.right:hover {
  text-shadow: 0px 0px 14px #264c7c;
}

.feed {
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
  overflow-x: scroll;
}


.feed::after {
  content: '';
  background: linear-gradient(270deg, #10100F 8.02%, rgba(16, 16, 15, 0) 104.94%);
  right: -10px;
  width: 40px;
  height: 80px;
  position: absolute;
}

/* Firefox */
.feed {
  scrollbar-width: thin;
  scrollbar-color: #254C7D #141414;
}

/* Chrome, Edge, and Safari */
.feed::-webkit-scrollbar {
  cursor: pointer;
  width: 1px;
  height: 4px;
}

.feed::-webkit-scrollbar-track {
  background: #1a1a1a;
  cursor: pointer;
}

.feed::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #254C7D;
  border-radius: 10px;
  border: 0px none #ffffff;
}

.block {
  display: flex;
  flex-direction: row;
  width: 212px;
  min-width: 212px;
  margin-bottom: 14px;
  padding: 15px 13px 12px 15px;
  background-color: #1a1a1a;
  border-radius: 16px;
  overflow: hidden;
}

.blockEmpty {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  height: 84px;
  width: 212px;
  min-width: 212px;
  border: 1px dashed rgba(199, 199, 199, 0.33);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
}

.emptyText {
  width: 100px;
  text-align: center;
  color: rgba(199, 199, 199, 0.33);
}

.image {
  width: 42px;
  height: 54px;
  border-radius: 10px;
}

.image img {
  border-radius: 5px;
}

.statsContainer {
  display: flex;
}

.row {
  flex-direction: row;
}

.col {
  margin-left: 16px;
  flex-direction: column;
}

.col:last-of-type{
  margin-top: 5px;
}

.title {
  font-weight: 200;
  font-size: 10px;
  line-height: 12px;
}

.data {
  margin-top: -2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.externalLink {
  cursor: pointer;
  font-size: 10px;
  line-height: 17px;
  font-weight: 300;
}

.externalLink:hover {
  color: #fff;
}

.externalLink:hover > .externalIcon{
  color: #fff;
}
.imageBlur {
  position: absolute;
  width: 42px;
  height: 54px;
  filter: blur(25px);
  opacity: .4;
  z-index: -1;
}

.imageMain {
  width: 42px;
  height: 54px;
  z-index: inherit;
}

.image {
  position: relative;
  width: 42px;
  height: 54px;
  z-index: 10;
}

.externalIcon {
  padding-right: 2px;
  width: 8px;
  font-weight: 800;
  color: #fff;
  line-height: 10px;
}

.region::after {
  position: absolute;
  width: 18px;
  height: 18px;
  background: red;
}