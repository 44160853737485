.block {
  width: 330px;
  min-width: 330px;
  height: 250px;
}

.newsBlock {
  cursor: pointer;
  width: 100%;
  height: 110px;
  background: linear-gradient(114.08deg, rgba(39, 81, 133, 0.6) -44.49%, #1A1A1A 96.62%);
  border-radius: 16px;
  margin-bottom: 30px;
  transition: all .55s ease;
}

.newsBlock:hover {
  background: linear-gradient(114.08deg, rgba(53, 111, 182, 0.6) -44.49%, #1A1A1A 96.62%);
}

.news {
  padding: 15px 17px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.data {
  display: block;
  padding-bottom: 5px;;
}

.category {
  margin-top: -3px;
  display: inline-block;
}

.categoryName {
  border-radius: 9px;
  padding: 2px 6px;
  background: #254C7D;
  font-size: 10px;
  line-height: 12px;
}

.date {
  padding-left: 4px;
  display: inline-block;
  font-size: 8px;
  color: #fff;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}