.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 20px;
}

.logo {
  position: relative;
  & > img {
    height: 20px;
    border-radius: 0;
    z-index: 1;
  }
  &::after {
    position: absolute;
    left: 0;
    top: 5px;
    content: '';
    width: 82px;
    height: 15px;
    background: rgb(253,1,255);
    background: linear-gradient(45deg, rgba(253,1,255,1) 0%, rgba(4,150,255,1) 50%, rgba(0,210,255,1) 100%);
    filter: blur(15px);
    opacity: .5;
    z-index: -1;
    transition: all 0.5s ease;
    animation: logoBlur 3s ease infinite;
  }
}

@keyframes logoBlur {
  0% {
    opacity: .3;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: .31;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 10px;
  background: #1A1A1A;
  border-radius: 17px;
  padding: 25px 18px 25px 18px;
}

.formReady {
  box-shadow: 0px 0px 322px #14e44212;
  transition: all 0.5s ease;
}

.formPending {
  transition: all 0.5s ease;
  animation: formPending 3s ease-in-out infinite;
}

@keyframes formPending {
  0% {
    box-shadow: 0px 0px 1000px #14e44128;
  }
  50% {
    box-shadow: 0px 0px 1000px #254d7d46;
  }
  100% {
    box-shadow: 0px 0px 1000px #14e44128;
  }
}

input {
  display: flex;
  background-color: #10100f;
  border: 0;
  padding: 10px 24px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #1A1A1A;
  border-radius: 9px;

  &:disabled {
    background-color: #1a1a1a;
    border: 1px solid rgb(129 132 145 / 22%);
    outline: none !important;
    color: #818491;
  }
}

button {
  position: relative;
  justify-content: center;
  height: 44px;
  width: 100%;
  color: #fcfcfd;
  font-weight: 500;
  padding: 10px 34px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #254C7D;
  background-color: #10100f;
  border-radius: 90px;
  cursor: pointer;
  transition: all .3s ease;

  &:disabled {
    cursor:not-allowed;
    border: 1px solid rgb(129 132 145 / 22%);
  }

  &:disabled:hover {
    cursor:not-allowed;
    border: 1px solid #818491;
    background-color: #81849122;
  }

  &:hover {
    background: #254C7D;
    background-color: rgba(37, 77, 125, 0.5);
  }

  &.pending {
    background-color: #1a1a1a;
    border: 1px solid rgb(129 132 145 / 22%);
  }

}

.scoreWord {
  font-size: 11px !important;
  font-weight: 600;
  text-align: left !important;

  &::after {
    content: ' пароль';
  }
}

.PasswordStrengthBar {
  padding: 0 8px 0 8px;
}

.passwordText {
  padding: 0px 8px 0px 8px;
  font-size: 10px;
  text-align: start;
  line-height: 12px;
  color: #818491;
  font-weight: 500;

  & > b {
    color: #a3a5ac;
    font-weight: 600;
  }
}

.valid {
  outline: 1px solid #14e442 !important;
}

.valid:focus {
  outline: 1px solid #14e442 !important;
}


.policy {
  padding: 0 8px;
  text-align: left;
  font-size: 10px;
  line-height: 12px;
  color: #818491;

  & > a {
    color: #a3a5ac;
    font-weight: 600;
  }
}

.loader {
  position: absolute;
  left: 59px;
  top: -2px;
}


.regText {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #818491;
  margin-top: 10px;

  & > a {
    margin-left: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 11px;
    background-color: #254C7D;
    padding: 4px 6px 5px 6px;
    border-radius: 8px;
    transition: all .3s ease;

    &:hover {
      background-color: #3265A6;
    }
  }
}