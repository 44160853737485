.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left {
  display: flex;
}

.right {
  display: flex;
  gap: 40px;
}

.backBtn {
  position: relative;
  line-height: 35px;
  width: 30px;
  margin-top: 21px;
  color: #fff;
  transform: rotate(45deg);
  box-sizing: content-box;
}

.backBtn::after {
  content: '';
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  background:#fff;
  height:3px;
  width:15px;
  border-radius:0 10px 10px 0;
}

.backBtn::before {
  content: '';
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  background:#fff;
  height:15px;
  width:3px;
  border-radius:10px 10px 0 0;
}

.backBtn:hover {
  cursor: pointer;
}

.backBtn:hover::after {
  cursor: pointer;
  background: #eee;
}

.backBtn:hover::before {
  cursor: pointer;
  background: #eee;
}

.headerTitle {
  display: inline-block;
  font-weight: 800;
  font-size: 40px;
  line-height: 24px;
  color: #fff;
  margin-left: -5px;

  & > .grey {
      color: #d2d2d2;
      font-weight: 200;
  }
}

.priceBox {
  position: relative;
  color: #fff;
  display: inline-block;
  font-weight: 800;
  font-size: 40px;
  line-height: 24px;
  color: #fff;
}

.ratingBox {
  position: relative;
  display: inline-block;
  font-weight: 800;
  font-size: 40px;
  line-height: 24px;
  color: #fff;
}

.rating {
  color: #fff;
}

.price {
  color: #fff;
  font-size: 28px;
  margin-top: -5px;
}

.articulTitle {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

.articul {
  display: flex;
  padding-right: 50px;
  bottom: -12px;
  right: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #d2d2d2;
  white-space: nowrap;
  gap: 5px;
}


.articulText {
  font-size: 10px;
}

.articulNumber {
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #fff;
  white-space: nowrap;
  padding: 2px 6px 2px 4px;
  background-color: #254C7D;
  border-radius: 9px;
}

.articulNumber:hover {
  cursor: pointer;
  background: #3265A6;
}

.externalIcon {
  height: 8px;
  padding-bottom: 1px;
  padding-left: 2px;
}

.discount {
  display: flex;
  position: absolute;
  bottom: -2px;
  right: 0px;
  font-weight: 400;
  line-height: 15px;
  color: #d2d2d2;
  white-space: nowrap;
  gap: 5px;
}

.discountText {
  font-size: 10px;
}

.discountNumber {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #E12B2B;
  white-space: nowrap;
  text-shadow: 0px 0px 12px #E12B2B;
}

.discountNumber::before {
  font-weight: 500;
  content: '-';
  color: #E12B2B;
}

.discountNumber::after {
  font-weight: 500;
  content: '%';
  color: #E12B2B;
}

.label {
  position: absolute;
  top: 0;
  margin-top: -22px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #d2d2d2;
}

.hint {
  position: absolute;
  top: 0;
  right: -20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  width: 15px;
  height: 15px;
  line-height: 12px;
  background: #254C7D;
  border-radius: 50%;
}
.hint::after {
  content: '?';
  line-height: 15px
}

.hint:hover {
  cursor: pointer;
  background: #3265A6;
}

.photoBox {
  position: relative;
  border-radius: 17px;
  min-width: 300px;
  max-width: 300px;
  overflow: hidden;
}

.photoSale {
  position: absolute;
  background-color: #EE133A;
  padding: 3px 9px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  top: 8px;
  left: 8px;
}

.photoBadges {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.photoBadge {
  background-color: #191919;
  padding: 3px 9px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

.photo {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.graphBox {
  position: relative;
  border-radius: 17px;
  height: 407px;
  width: 100%;
  background-color: #161828;
  overflow: hidden;
}

.graphWideBox {
  position: relative;
  height: 480px;
  width: 100%;
  background-color: #161828;
}

.graphTitle {
  position: absolute;
  color: #fff;
  margin-left: 330px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  width: 250px;
}

.ratingTip {
  background-color: #1a1a1a;
  padding: 15px 15px !important;
  max-width: 250px !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  border-radius: 12px !important;
  white-space: normal !important;
  text-align: left !important;
  opacity: 1 !important;

  & > span {
    margin-top: 10px;
    display: block;
    padding: 5px 10px;
    background-color: #254C7D;
    border-radius: 8px;
  }
}
