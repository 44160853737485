.menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: flex-start;
  align-items: center;
}

.keywords {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #818491;
}

.categories {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #818491;
}

.keywords > span {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-color: #818491;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  cursor: pointer;
}

.categories > span {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-color: #818491;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  cursor: pointer;
}

.active > span {
  color: #fff;
  text-decoration: none;
}

.active > .count {
  color: #fff;
}

.region {
  select {
    cursor: pointer;
    position: relative;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    border: 0;
    background-color: transparent;
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-decoration-color: #fff;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    option {
      background: #10100f;
      color: #818491;
      font-weight: 600;
    }
  }
  &:active {
    border: 0;
  }
}

.count {
  position: absolute;
  right: -5px;
  top: -6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #818491;
}

.search {
  position: relative;
  input {
    height: 38px;
    padding: 10px 50px 10px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    border: 1px solid #254c7d;
    border-radius: 39px;
    box-shadow: 0px 0px 10px #254d7d94;

    &::placeholder {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #818491;
    }
  }

  .searchIcon {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #10100f;
    color: #818491;
  }
}

.unique {
  position: relative;
  margin-left: auto;
  button {
    cursor: pointer;
    background: transparent;
    white-space: nowrap;
    color: #fff;
    height: 38px;
    padding: 10px 40px 10px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid #254c7d;
    border-radius: 39px;
    box-shadow: 0px 0px 10px #254d7d94;

    &:hover {
      background-color: rgba(37, 77, 125, 0.5);
      transition: all 0.2s ease;
    }

    &.active {
      background-color: rgba(44, 91, 148, 0.5);
    }
  }
}

.hint {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  width: 15px;
  height: 15px;
  line-height: 12px;
  background: #254c7d;
  border-radius: 50%;
}
.hint::after {
  content: "?";
  line-height: 15px;
}

.hint:hover {
  cursor: pointer;
  background: #3265a6;
}

.tableStats {
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  border-collapse: separate;
  border-spacing: 0px;

  thead {
    th {
      width: 45px;
      min-width: 45px;
      text-align: center;
      cursor: pointer;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #818491;
      padding-bottom: 6px;
    }
  }

  tbody {
    position: relative;
    tr {
      td {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        height: 33px;
        border: 1px solid #1a1a1a;
        border-spacing: 0;
        border-collapse: separate;
        box-sizing: content-box;
        background-color: rgba(28, 52, 81, 0.15);
      }
    }
  }
}

.tableStatsWrap {
  position: relative;
  width: 100%;
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #254c7d #141414;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    cursor: pointer;
    width: 1px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #254c7d;
    border-radius: 10px;
    border: 0px none #ffffff;
  }
}

.statsContent {
  gap: 0;
  margin-top: 10px;
}

.table {
  width: 100%;
  border: 1px solid #262626;
  border-radius: 17px;
  overflow: hidden;
}

table {
  border-collapse: collapse;
  position: relative;
  color: #fff;
  width: 100%;

  thead {
    border-bottom: 5px solid #262626;    
    
    th {
      padding: 15px 0;
      cursor: pointer;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #818491;

      &:first-of-type {
        width: 22px;
      }

      &.orderAsc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        // display: flex;
        color: #b0b0b0;

        &::after {
          position: absolute;
          right: -3px;
          top: 4px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }

      &.orderDesc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        // display: flex;
        color: #b0b0b0;

        &::after {
          position: absolute;
          right: -3px;
          top: 4px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 2px 0 0 2px;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }
    }

    .orderWrap {
      display: initial;

      &.orderAsc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #b0b0b0;

        &::after {
          position: absolute;
          right: -10px;
          top: 4px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }

      &.orderDesc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #b0b0b0;

        &::after {
          position: absolute;
          right: -10px;
          top: 7px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 2px 0 0 2px;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }
    }
    .orderWrapLeft {
      display: initial;

      &.orderAsc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #b0b0b0;

        &::after {
          position: absolute;
          left: -10px;
          top: 4px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }

      &.orderDesc {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #b0b0b0;

        &::after {
          position: absolute;
          left: -10px;
          top: 7px;
          width: 0px;
          height: 0px;
          content: "";
          border: solid #818491;
          border-width: 2px 0 0 2px;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
        }
      }
    }

    .queryType {
      text-align: left;
    }

    .keyword {
      text-align: left;
    }

    .position {
      width: 63px;
      text-align: right;
    }

    .views {
      width: 161px;

      .hint {
        position: absolute;
        z-index: 1;
        top: -4px;
        right: 32px;
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 10px;
        width: 15px;
        height: 15px;
        line-height: 12px;
        background: #254c7d;
        border-radius: 50%;

        &::after {
          content: "?";
          line-height: 15px;
        }

        &:hover {
          cursor: pointer;
          background: #3265a6;
        }
      }
    }

    .difference {
      width: 128px;
      padding-left: 36px;

      .hint {
        display: inline-block;
        top: -4px;
        right: 20px;
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 10px;
        width: 15px;
        height: 15px;
        line-height: 12px;
        background: #254c7d;
        border-radius: 50%;
      }
      .hint::after {
        content: "?";
        line-height: 15px;
      }

      .hint:hover {
        cursor: pointer;
        background: #3265a6;
      }
    }
  }

  tbody {
    tr {
      height: 50px;

      &:nth-child(2n+1) {
        background-color: #161616;
      }

      td {
        padding: 8px 0px 8px 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;

        &:nth-child(1){
          padding-left: 13px;
          padding-right: 13px;
        }

        &:nth-last-child(1){
          padding-right: 10px;
        }

        label {
          cursor: pointer;
          display: block;
          position: relative;
          width: 11px;
          height: 11px;
          background: #10100f;
          border: 1px solid #254c7d;
          border-radius: 2px;

          &::after {
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            z-index: -1;
            background: #254c7d;
            filter: blur(8px);
          }

          &:hover {
            border: 1px solid rgb(219, 219, 219);
          }

          &[color] {
            border: 1px solid #fff;
            background: var(--keyBoxColor);

            &::after {
              background: var(--keyBoxColor);
            }
          }

          &[color]:hover {
            border: 1px solid rgb(219, 219, 219);
          }

          &[color="false"] {
            border: 1px solid #254c7d;
          }

          input {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }
        }
      }

      .badge {
        position: relative;
        display: inline;
        padding: 3px 10px 3px 10px;
        border-radius: 1000px;
        font-weight: 500;
      }

      .badgepreset {
        color: hsl(337, 62%, 80%);
        // background-color: rgba(212, 69, 124, .5);
        // border: 1px solid #d4457c;
        
        &::before{
          display: inline-block;
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: hsl(337, 62%, 50%);
          margin-right: 3px;
        }
      }

      .badgebrand {
        color: hsla(263, 64%, 80%);
        // background-color: hsla(263, 64%, 50%, .5);
        // border: 1px solid hsla(263, 64%, 50%);
        
        &::before{
          display: inline-block;
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: hsla(263, 64%, 50%);
          margin-right: 3px;
        }
      }

      .badgemerger {
        color: hsla(51, 62%, 80%);
        // background-color: hsla(51, 62%, 50%, .5);
        // border: 1px solid hsla(51, 62%, 50%);
        
        &::before{
          display: inline-block;
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: hsla(51, 62%, 50%);
          margin-right: 3px;
        }
      }

      .badgesubject {
        color: hsla(152, 62%, 80%);
        // background-color: hsla(152, 62%, 50%, .5);
        // border: 1px solid hsla(152, 62%, 50%);
        
        &::before{
          display: inline-block;
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: hsla(152, 62%, 50%);
          margin-right: 3px;
        }
      }

      .progress {
        background-color: #18283a;
        width: 100%;
        height: 6px;
        border-radius: 1000px;
        overflow: hidden;

        & > .bar {
          background-color: #3265a6;
          height: 100%;
          border-radius: 1000px;
        }
      }

      .keyword {
        position: relative;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        max-width: 226px;
        overflow: hidden;
        white-space: nowrap;

        & > a {
          display: inline;
          position: relative;
          color: #3265a6;
          padding: 4px 3px;
          font-size: 10px;
          font-weight: 600;
          background-color: #18283a;
          border-radius: 3px;

          &:hover {
            color: #fff;
          }

          &::after {
            content: "";
          }
        }

        .text {
          cursor: pointer;
          display: inline-block;
          margin-left: 4px;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;

          &:hover {
            color: rgb(255, 255, 255);
          }

          // &::first-letter {
          //   text-transform: uppercase;
          // }
        }

        & .sup {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          font-size: 9px;
          margin-top: -26px;
          line-height: 13px;
          left: -1px;
          opacity: 0.9;

          & > a {
            position: relative;
            color: #3265a6;
            padding: 0px 2px;
            font-size: 8px;
            font-weight: 600;
            background-color: #18283a;
            border-radius: 3px;

            &:hover {
              color: #fff;
            }
          }

          & > div {
            display: inline;
            margin-left: 3px;
          }

          & .arrowToTotal {
            display: inline;
            font-size: 12px;
            padding-left: 3px;
            font-weight: 100;
          }

          & .total {
            padding-left: 3px;
            font-size: 9px;
            font-weight: 600;
          }

          & .diffUp {
            color: #e12b2b;
            padding-left: 10px;
            font-weight: 600;

            &::before {
              content: "";
              width: 6px;
              height: 6px;
              position: absolute;
              margin-left: -8px;
              margin-top: 4px;
              background-attachment: fixed;
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%23E12B2B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 1h5m0 0v5m0-5L1 7'/%3E%3C/svg%3E")
                center no-repeat;
            }
          }

          & .diffDown {
            color: #14e442;
            padding-left: 10px;
            font-weight: 600;

            &::before {
              content: "";
              width: 6px;
              height: 6px;
              position: absolute;
              margin-left: -8px;
              margin-top: 4px;
              background-attachment: fixed;
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%2314E442' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 1h5m0 0v5m0-5L1 7'/%3E%3C/svg%3E")
                center no-repeat;
            }
          }
        }
      }

      .position {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
      }

      .views {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .difference {
        padding-left: 36px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        & > span {
          margin-left: 4px;
        }
      }
    }
  }
}

.skeleton {
  margin-top: 30px;
  position: absolute;
  display: flex;
  width: calc(100% - 253px);
}

.arrowUp {
  display: inline-block;
  position: relative;
  width: 11px;
  height: 11px;
  z-index: 1;
  line-height: 21px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%2314E442' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 1h5m0 0v5m0-5L1 7'/%3E%3C/svg%3E")
    no-repeat;
  background-position: center;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -1px;
    background-color: #14e442;
    filter: blur(4px);
    opacity: 0.2;
    z-index: 0;
  }
}

.arrowDown {
  display: inline-block;
  position: relative;
  width: 11px;
  height: 11px;
  z-index: 1;
  line-height: 21px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3E%3Cpath stroke='%23E12B2B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 2v5m0 0H2m5 0L1 1'/%3E%3C/svg%3E")
    no-repeat;
  background-position: center;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -1px;
    background-color: #e12b2b;
    filter: blur(4px);
    opacity: 0.2;
    z-index: 0;
  }
}

.arrowNull {
  display: inline-block;
  position: relative;
  width: 11px;
  height: 11px;
  z-index: 1;
  line-height: 21px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgOCAzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNy4wMDAxOSAxLjI0MjY0SDAuOTk5ODA1IiBzdHJva2U9IiM4MTg0OTEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: center;
  background-repeat: no-repeat;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -1px;
    background-color: #818491;
    filter: blur(4px);
    opacity: 0.2;
    z-index: 0;
  }
}

.uniqueKeysTip {
  background-color: #1a1a1a;
  padding: 15px 15px !important;
  max-width: 300px !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  border-radius: 12px !important;
  white-space: normal !important;
  text-align: left !important;
  opacity: 1 !important;

  & > span {
    margin-top: 10px;
    display: block;
    padding: 5px 10px;
    background-color: #254c7d;
    border-radius: 8px;
  }
}

.viewsTip {
  background-color: #1a1a1a;
  padding: 15px 15px !important;
  max-width: 300px !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  border-radius: 12px !important;
  white-space: normal !important;
  text-align: left !important;
  opacity: 1 !important;

  & > span ~ span {
    background-color: #e12b2b;
  }

  & > span {
    margin-top: 10px;
    display: block;
    padding: 5px 10px;
    background-color: #254c7d;
    border-radius: 8px;
  }
}

.diffTip {
  background-color: #1a1a1a;
  padding: 15px 15px !important;
  max-width: 300px !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  border-radius: 12px !important;
  white-space: normal !important;
  text-align: left !important;
  opacity: 1 !important;

  & > span ~ span {
    background-color: #e12b2b;
  }

  & > span {
    margin-top: 10px;
    display: block;
    padding: 5px 10px;
    background-color: #254c7d;
    border-radius: 8px;
  }
}
