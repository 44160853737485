.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.version {
  position: absolute;
  right: 35px;
  font-weight: 600;
  top: 36px;
  font-size: 8px;
  color: #818491;
}

.logo {
  position: relative;
  cursor: pointer;
  padding-top: 46px;
  padding-bottom: 26px;
  text-align: center;
  color: #d2d2d2;
  filter: drop-shadow(0 0 40px #1051b3);

  & > img {
    width: 160px;
    border-radius: 0;
    z-index: 1;
  }

  &::after {
    position: absolute;
    left: 35px;
    top: 49px;
    content: '';
    width: 72px;
    height: 15px;
    background: rgb(253,1,255);
    background: linear-gradient(45deg, rgba(253,1,255,1) 0%, rgba(4,150,255,1) 50%, rgba(0,210,255,1) 100%);
    filter: blur(15px);
    opacity: .5;
    z-index: -1;
    transition: all 0.3s ease;
  }

  &:hover::after {
    opacity: .8;
  }

  &:active:hover::after {
    opacity: .8;
    transform: scale(1.3);
    background: linear-gradient(45deg, rgba(253,1,255,1) 0%, rgba(4,150,255,1) 50%, rgba(0,210,255,1) 100%);
  }
}