.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.right {
  display: flex;
  gap: 40px;
}

.backBtn {
  position: relative;
  line-height: 35px;
  width: 30px;
  margin-top: 21px;
  color: #fff;
  transform: rotate(45deg);
  box-sizing: content-box;
}

.backBtn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 3px;
  width: 15px;
  border-radius: 0 10px 10px 0;
}

.backBtn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 15px;
  width: 3px;
  border-radius: 10px 10px 0 0;
}

.backBtn:hover {
  cursor: pointer;
}

.backBtn:hover::after {
  cursor: pointer;
  background: #eee;
}

.backBtn:hover::before {
  cursor: pointer;
  background: #eee;
}

.headerTitle {
  display: inline-flex;
  font-weight: 800;
  font-size: 40px;
  line-height: 24px;
  color: #fff;
  margin-left: -5px;

  & > .grey {
    color: #d2d2d2;
    font-weight: 200;
  }
}

.search {
  position: relative;
  display: flex;
  width: 100%;
  & > .searchInput {
    width: 100%;
    height: 42px;
    padding: 10px 20px 10px 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    background-color: #080808;
    outline: none;
    border: none;
    border-radius: 39px;
    transition: 0.5s box-shadow;

    @keyframes inputLoading {
      from {
        background: linear-gradient(
          90deg,
          (#000, 0%),
          (#eee, 50%),
          (#000, 100%)
        );
      }

      to {
      }
    }

    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #818491;
    }

    &:focus {
      box-shadow: 0px 0px 25px -5px #254d7d94;
    }

    &:active {
      border: none;
    }
  }

  & > .suggested {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .searchIcon {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #10100f;
    color: #818491;
  }
  .suggestions {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 10;

    & > ul {
      list-style: none;

      & > li {
        padding: 10px 40px;
        color: #fff;
        font-weight: 400;
        background-color: #080808;
        border-top: 1px solid rgb(24, 24, 24);

        &:nth-last-child(1) {
          padding-bottom: 15px;
          border-bottom-left-radius: 22px;
          border-bottom-right-radius: 22px;
        }

        &:hover {
          cursor: pointer;
          background-color: #191919;
        }
      }
    }
  }
}

.products {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  & > .product {
    display: flex;
    position: relative;
    max-width: 246px;
    flex: 1;
    flex-direction: column;

    &:hover {
      cursor: pointer;
      text-shadow: 0 0 1px white;
    }

    & > span > .photo {
      width: 246px;
      height: 328px;
      border-radius: 17px;
    }

    .promo {
      position: absolute;
      background-color: #ee133a;
      padding: 3px 9px;
      border-radius: 12px;
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      top: 8px;
      left: 8px;
    }

    & > .topLine {
      display: flex;
      margin-top: 5px;
      font-weight: 400;
      font-size: 13px;
      line-height: 12px;
      justify-content: space-between;

      & > .discountPhrase {
        & > span {
          font-weight: 400;
          font-size: 13px;
          line-height: 12px;
          color: rgb(225, 43, 43);
          text-shadow: 0px 0px 10px rgb(225, 43, 43);
        }
      }
      & > .leftPhrase {
      }
    }

    & > .priceLine {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      margin-top: 3px;
      line-height: 12px;
      justify-content: space-between;

      & > .price {
        font-weight: 700;
        font-size: 26px;
        line-height: 29px;
        color: #fff;
      }
      & > .stocksLeft {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #fff;
        border: 1px solid #254c7d;
        border-radius: 5px;
        padding: 4px 10px 4px 10px;
        & > span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }

        &:hover {
          background-color: #1d3c63;
        }
      }
    }

    & > .brand {
      font-weight: 800;
      font-size: 14px;
      line-height: 15px;
    }

    & > .title {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.titler {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;
  flex-direction: column;

  & > .title {
    color: #fff;
    font-size: 26px;
    font-weight: 800;
  }

  & > .subtitle {
    font-size: 20px;
    font-weight: 400;

    & > span {
      font-weight: 600;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background: linear-gradient(90deg, #6ff, #7966ff);
      -webkit-background-clip: text;
      transition: 0.3s all;

      &:hover {
        cursor: pointer;
        border-bottom: 1px dotted #6e6e6e;
      }
    }
  }
}

.stocksHint {
  background-color: #1a1a1a;
  padding: 15px 15px !important;
  max-width: 300px !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  border-radius: 12px !important;
  white-space: normal !important;
  text-align: left !important;
  opacity: 1 !important;

  & > p {
    line-height: 15px;
    & > b {
      padding-left: 10px;
    }
  }

  & > span {
    margin-top: 10px;
    display: block;
    padding: 5px 10px;
    background-color: #254c7d;
    border-radius: 8px;
  }
}
.result {
  display: flex;
  flex-direction: row;
  width: 100%;
}