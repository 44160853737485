.header {
  display: flex;
  align-items: center;
  max-width: 1078px;
  min-width: 1078px;
  height: 140px;
  background: #10100f;
  z-index: 11;
  top: 0;
  padding: 30px 0px 30px 20px;
}
