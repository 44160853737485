.folder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  color: #808191;
  padding: 10px 26px;
  z-index: 2;
}