.campaignsBlock {
  position: relative;
  max-width: 618px;
  margin-top: 25px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 180px;
  min-width: 180px;
  padding: 8px 12px 8px 10px;
}

.graphSection {
  width: 187px;
  min-width: 187px;
  padding: 0;
  background-color: #1A1A1A;
}

.matrixSection {
  width: 100%;
  padding: 10px 10px 9px 10px;
}

.matrixHeader {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}

.matrix {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 0px;
  margin-top: 5px;
}

.matrixBlock {
  display: flex;
}

.matrixCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1A1A1A;
  border-radius: 6px 0px 0px 6px;
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;
  width: 14px;
  padding: 5px 4px 4px 5px;
  text-align: center;
}

.matrixData {
  background-color: #10100F;
  border-radius: 0px 6px 6px 0px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  width: 30px;
  padding: 5px 4px 4px 4px;
}

.matrixActive {
  position: relative;
  z-index: 10;
}

.matrixActive::before {
  content: '';
  z-index: -1;
  width: 40px;
  margin-left: 2px;
  margin-top: 1px;
  height: 17px;
  border-radius: 6px;
  position: absolute;
  background-color: #2FA61B;
  filter: blur(15px);
  opacity: .7;
}

.matrixActive > .matrixCounter {
  background-color: #2FA61B;
  color: #fff;
}

.matrixActive > .matrixData {
  background-color: #227B13;
  font-weight: 500;
  color: #fff;
}

.feed::after {
  content: '';
  background: linear-gradient(0deg, #10100F 8.02%, rgba(16, 16, 15, 0) 104.94%);
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 30px;
  position: absolute;
  z-index: 10;
}

.header{
  display: block;
}
.headerTitle {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.right {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  position: absolute;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.25s ease;
}

.right:hover {
  text-shadow: 0px 0px 14px #264c7c;
}

.feed {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
}

/* Firefox */
.feed {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: #254C7D #141414;
}

/* Chrome, Edge, and Safari */
.feed::-webkit-scrollbar {
  display: none;
  cursor: pointer;
  width: 1px;
  height: 4px;
}

.feed::-webkit-scrollbar-track {
  background: #1a1a1a;
  cursor: pointer;
}

.feed::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #254C7D;
  border-radius: 10px;
  border: 0px none #ffffff;
}

.block {
  display: flex;
  position: relative;
  min-height: 104px;
  max-width: 100%;
  margin-bottom: 14px;
  background-color: #1F1F1F;
  border-radius: 16px;
  overflow: hidden;
}

.image {
  width: 42px;
  height: 54px;
  border-radius: 10px;
}

.image img {
  border-radius: 5px;
}

.statsContainer {
  display: flex;
  width: 100%;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.col {
  margin-left: 16px;
  flex-direction: column;
}

.title {
  display: flex;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
}

.campaignName {
  display: flex;
  font-weight: 600;
  font-size: 10px;
  color: #808191;
  white-space: nowrap;
  overflow: hidden;
}

.data {
  display: flex;
  margin-top: -2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #FFFFFF;
}

.externalLink {
  cursor: pointer;
  font-size: 10px;
  line-height: 17px;
  font-weight: 300;
}

.externalLink:hover {
  color: #fff;
}

.externalLink:hover > .externalIcon{
  color: #fff;
}
.imageBlur {
  position: absolute;
  
  width: auto;
  height: auto;
  max-width: 40px;
  filter: blur(25px);
  opacity: .4;
  z-index: -1;
}

.imageMain {
  width: auto;
  height: auto;
  max-width: 40px;
  z-index: inherit;
}

.image {
  position: relative;
  width: 42px;
  height: 54px;
  z-index: 10;
}

.externalIcon {
  padding-right: 2px;
  width: 8px;
  font-weight: 800;
  color: #fff;
  line-height: 10px;
}

.region::after {
  position: absolute;
  width: 18px;
  height: 18px;
  background: red;
}

.sku {
  display: inline-block;
  right: 12px;
}

.cardLine {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sb {
  justify-content: space-between
}

.status {
  display: inline-flex;
  background: #10100F;
  border-radius: 10px;
  line-height: 20px;
  align-items: center;
  padding: 0px 8px 0px 6px;
}

.category {
  display: inline-flex;
  position: relative;
  background: #254C7D;
  border-radius: 8px;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  padding: 0px 5px;
}

.category::before {
  position: absolute;
  left: -20px;
  background: linear-gradient(270deg, #1a1a1a 0%, #1a1a1a00 94.44%);
  content: '';
  width: 20px;
  height: 20px;
}


.circle {
  width: 6px;
  height: 6px;
  background-color: #227B13;
  border-radius: 50%;
}

.statusName {
  display: inline-block;
  font-weight: 400;
  font-size: 10px;
  margin-left: 5px;
  color: #FFFFFF;
}